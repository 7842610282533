<template>
  <v-autocomplete
    v-model="value"
    outlined
    :items="settlementOptions"
    :label="field.text"
    :rules="validators"
    append-icon="mdi-chevron-down"
    dense
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    ...mapState({
      settlementTypes: state => state.core.filters?.clientSettlementTypes
    }),
    isCompanyClient() {
      const { clientType } = this.data.client || this.data
      return clientType === 'Firma'
    },
    settlementOptions() {
      return this.settlementTypes?.filter(type => {
        return this.isCompanyClient ? type.company : type.individual
      }).map(settlement => settlement.type) || []
    },
    value: {
      get () {
        return get(this.data, this.field.value, '')
      },
      set (value) {
        this.$emit('update', { settlementType: value })
      }
    },
  },
}
</script>
